import { Web3ReactProvider } from '@web3-react/core';
import { FetchingStatus, TOAST_CONFIG } from 'config/constants';
import { RouteConfig } from 'config/constants/route';
import ModalsProvider from 'contexts/Modals';
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect';
import { useRouter } from 'hooks/useRouter';
import 'i18n';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch } from 'state';
import { initMetadata, setIsSigned } from 'state/app/actions';
import { fetchMetaData } from 'state/app/calls/fetchMetaData';
import { useIsInGame } from 'state/app/hooks';
import { useAuth, useAuthRefresh } from 'state/auth/hooks';
import { initSystemData } from 'state/system/actions';
import { fetchSystemData } from 'state/system/calls/fetchSystemData';
import { useSystemData } from 'state/system/hooks';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/Global';
import ResetCSS from 'styles/ResetCSS';
import theme from 'theme';
import { forkjoinRequest } from 'utils/requestHelper';
import { getLibrary } from 'utils/web3React';

const Providers = ({ store, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ResetCSS />
      <GlobalStyle />
      <Provider store={store}>
        <Auth />
        <FetchServiceInfo />
        <Web3Provider>
          <ModalsProvider>{children}</ModalsProvider>
          <ToastContainer {...TOAST_CONFIG} />
        </Web3Provider>
      </Provider>
    </ThemeProvider>
  );
};

const Web3Provider = ({ children }) => {
  return <Web3ReactProvider getLibrary={getLibrary}>{children}</Web3ReactProvider>;
};

const FetchServiceInfo = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [fetchingMetaStatus, setFetchingMetaStatus] = useState(FetchingStatus.Unknown);

  const systemData = useSystemData();

  useIsomorphicEffect(() => {
    const fetch = async () => {
      const [data] = await forkjoinRequest([fetchMetaData()]);
      if (!data) {
        setFetchingMetaStatus(FetchingStatus.Failed);
        return;
      }

      dispatch(
        initMetadata({
          data: { ...data },
        }),
      );

      setFetchingMetaStatus(FetchingStatus.Fetched);
    };

    const fetchSystem = async () => {
      const systemData = await fetchSystemData();
      dispatch(
        initSystemData({
          isEnableMaintenanceMode: systemData?.isEnableMaintenanceMode,
          serviceOpenAt: systemData?.openAt,
          serviceShutdownAt: systemData?.shutDownAt,
        }),
      );
    };
    fetchSystem();
    fetch();
  }, []);

  useEffect(() => {
    if (
      (systemData.isServiceShutdown || fetchingMetaStatus === FetchingStatus.Failed) &&
      router.pathname !== RouteConfig.Maintenance
    ) {
      router.push(RouteConfig.Maintenance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingMetaStatus, systemData.isServiceShutdown]);

  return null;
};

const Auth = () => {
  const { isSigned, hasSession } = useAuth();
  const refresh = useAuthRefresh();
  const dispatch = useAppDispatch();
  const isInGame = useIsInGame();

  useEffect(() => {
    if (!isSigned && !hasSession) dispatch(setIsSigned({ isSigned: false }));
    if (isSigned || !hasSession || isInGame) return;

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession, isSigned, isInGame]);

  // TODO
  // useEffect(() => {
  //   if (!isSigned) return;

  //   if (!signedAtTime) dispatch(setIsSigned({ isSigned: false }));
  //   else if (new Date().getTime() - signedAtTime > 240000) dispatch(setIsSigned({ isSigned: false }));
  //   else {
  //     const timeout = setTimeout(() => {
  //       dispatch(setIsSigned({ isSigned: false }));
  //     }, new Date().getTime() - signedAtTime + 240000);
  //     return () => {
  //       clearTimeout(timeout);
  //     };
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [signedAtTime, isSigned]);

  return null;
};

export default Providers;
