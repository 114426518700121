import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'state';
import { useIsInGame } from 'state/app/hooks';
import { initSystemData } from './actions';
import { fetchSystemData } from './calls/fetchSystemData';

export const useSystemData = () => {
  const system = useAppSelector((state) => state.system);
  const serverTimeMargin = useAppSelector((state) => state.app.serverTimeMargin) || 0;

  const isServiceShutdown = useMemo(() => {
    if (system?.isEnableMaintenanceMode) {
      const rightJustNow = new Date().getTime() + serverTimeMargin;
      const shutDownTime = system.serviceShutdownAt + serverTimeMargin;
      const openTime = system.serviceOpenAt + serverTimeMargin;

      if (rightJustNow > shutDownTime && rightJustNow < openTime) {
        return true;
      }
      return false;
    }
    return false;
  }, [system, serverTimeMargin]);

  return useMemo(
    () => ({
      serviceShutdownAt: system.serviceShutdownAt + (serverTimeMargin || 0),
      serviceOpenAt: system.serviceOpenAt + serverTimeMargin,
      isEnableMaintenanceMode:
        system.isEnableMaintenanceMode &&
        new Date().getTime() + serverTimeMargin < system.serviceOpenAt + serverTimeMargin,
      isServiceShutdown,
    }),
    [system, serverTimeMargin, isServiceShutdown],
  );
};

export const usePollSystemData = () => {
  const dispatch = useAppDispatch();
  const isInGame = useIsInGame();

  useEffect(() => {
    let interval;
    const fetch = async () => {
      if (!isInGame) {
        clearInterval(interval);
        return;
      }

      const systemData = await fetchSystemData();
      if (!systemData) return;

      dispatch(
        initSystemData({
          isEnableMaintenanceMode: systemData.isEnableMaintenanceMode,
          serviceOpenAt: systemData.openAt,
          serviceShutdownAt: systemData.shutDownAt,
        }),
      );
    };
    if (!isInGame) return;
    interval = setInterval(fetch, 300000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, isInGame]);
};
