import { APIEndpointEnum, BASE_API, BASE_API_HUNNY, SERVER_JSON, STAGING_MODE } from 'config/constants/server';
import { Token } from 'config/types';
import { hunnyAxios } from './BaseRequest';
import { HunnyPokerRequest } from './HunnyPokerRequest';
import mapper from './mapper/Mapper';
import { MetaDataResponse, TokenUsdPriceResponse } from './types';

class MetaService extends HunnyPokerRequest {
  public async getServiceInfo(): Promise<{
    shutDownAt: number;
    openAt: number;
    isEnableMaintenanceMode: boolean;
  }> {
    const result = await this._get(`${SERVER_JSON}?time=${Date.now()}`);

    if (!result) {
      return null;
    }

    const shutDownAt = STAGING_MODE ? 1000000000000 : result.from_time * 1000;
    const openAt = STAGING_MODE ? 1000000000000 : result.to_time * 1000;
    const status = STAGING_MODE ? false : result?.status === -1;

    return { shutDownAt, openAt, isEnableMaintenanceMode: status };
  }

  public async getMetaData(): Promise<MetaDataResponse> {
    const result = await this._request(
      APIEndpointEnum.Meta,
      {
        code: 'POKER',
      },
      {
        baseURL: BASE_API_HUNNY || BASE_API,
      },
    );

    if (!result) {
      return null;
    }

    return result.data;
  }

  public async getTokenInUsdPrices(tokens: Token[]): Promise<TokenUsdPriceResponse> {
    try {
      const result = await hunnyAxios.get(APIEndpointEnum.TokenUsdPrices, {
        params: {
          ids: tokens
            .map((token) => token.coinGeckoInfo?.id)
            .filter((id) => id)
            .join(','),
          vs_currencies: 'usd',
        },
        transformResponse: mapper.getMapper(APIEndpointEnum.TokenUsdPrices),
      });
      if (!result) return null;
      return result.data;
    } catch {
      return null;
    }
  }
}

const instance = new MetaService();
export default instance;
