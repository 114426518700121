import { createGlobalStyle } from 'styled-components';
import { Colors, HunnyTheme } from 'theme/types';

declare module 'styled-components' {
  export interface DefaultTheme extends HunnyTheme {
    colors: Colors;
  }
}

const GlobalStyle = createGlobalStyle`

  :root {  
    --screen-height: ${() => (typeof window !== 'undefined' ? `${window.innerHeight}px` : '100vh')};
    --notification-bar-height: 0px;
  }

  html {
    scrollbar-track-color: transparent;
    scrollbar-arrow-color: transparent;
    scroll-behavior: smooth;
  }

  body {
    margin: 0px;
    min-height: var(--screen-height);
    display: flex;
    flex-direction: column;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  body::-webkit-scrollbar {
    margin-left: 10px;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: ${({ theme }) => theme.colors.textSubtle}; 
    border-radius: 5px;
    background-clip: padding-box;
  }

  body::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.textSubtle};
    border-radius: 5px;

    background-clip: none;

    border-left: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-resizer {
    background-color: transparent;
  }

  body {
    background:  ${({ theme }) => theme.colors.background};

    margin: 0;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: overlay;
  }

  * {
    font-family: 'Montserrat';
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .hunny-lazy-list:has(.hunny-list-loadmore-btn){
    position: relative;
    padding-bottom: 84px;
  }

  .hunny-lazy-list:has(.hunny-list-process){
    position: relative;
    padding-bottom: 60px;

    :has(.hunny-list-loadmore-btn){
      padding-bottom: 144px;
    }

    .hunny-list-loadmore-btn{
      position: absolute;
      left: 50%;
      bottom: 72px;
      transform: translateX(-50%) !important;
      height: 48px;
    }
  }

  .hunny-lazy-list:has(.list-no-content){
    padding: 100px !important;
    position: relative;

  }

  .hunny-lazy-list {
    .hunny-list-loadmore-btn{
      position: absolute;
      left: 50%;
      bottom: 24px;
      transform: translateX(-50%) !important;
      height: 48px;
    }

    .hunny-list-process{
      position: absolute;
      left: 50%;
      bottom: 24px;
      transform: translateX(-50%) !important;
      height: 24px;
    }

    .list-no-content {
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      transform: translateX(-50%) !important;
    }
  }


  

  .Toastify {
    font-size: 16px;
    font-weight: 600;

    .Toastify__toast-container{
      width: auto;
      max-width: 500px;
    }
  }

  .Toastify__close-button--default {
    color: #fff;
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
      top: 60px !important;
      padding: 0px 12px !important;
    }
  }

  .Toastify__toast-container--top-right {
    top: 60px !important;
    right: 1em !important;
  }

  .__react_component_tooltip {
    transition: all 0.1s ease 0s !important;
    background: rgba(225, 225, 225, 0.1) !important;
    backdrop-filter: blur(3px);
    border-radius: 8px;
  }

  .__react_component_tooltip.place-left::after {
    backdrop-filter: blur(3px);
    border-left-color: rgba(225, 225, 225, 0.1) !important;
    border-left-width: 5px !important;
  }

  .__react_component_tooltip.place-right::after {
    backdrop-filter: blur(3px);
    border-right-color: rgba(225, 225, 225, 0.1) !important;
    border-right-width: 5px !important;
  }

  .__react_component_tooltip.place-top::after {
    backdrop-filter: blur(3px);
    border-top-color: rgba(225, 225, 225, 0.1) !important;
    border-top-width: 5px !important;
  }

  .__react_component_tooltip.place-bottom::after {
    backdrop-filter: blur(3px);
    border-bottom-color: rgba(225, 225, 225, 0.1) !important;
    border-bottom-width: 5px !important;
  }

  .__react_component_tooltip.show {
    opacity: 1 !important;
  }

  @media (max-width: 425px) {
    .__react_component_tooltip.show {
      opacity: 1 !important;
      left: 20px !important;
      width: 100vw;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
    }

    .__react_component_tooltip::after,
    .__react_component_tooltip::after {
      content: unset !important;
    }
  }

  .__react_component_tooltip .multi-line {
    text-align: left !important;
  }

  body.no-scroll {
    overflow: hidden !important;
  }

  @media only screen and (max-width: 856px) {
    #crisp-chatbox div:not(.container-crisp)>a {
      display: none !important;
    }

    #crisp-chatbox .container-crisp>a {
      display: none !important;
    }

    #crisp-chatbox>.container-crisp>div {
      bottom: 80px !important;
    }

    #crisp-chatbox .container-crisp.display>a {
      display: block !important;
      bottom: 80px !important;
      transform: scale(0.9);
    }

    #crisp-chatbox .container-crisp.display>div {
      bottom: 140px !important;
    }

    #crisp-chatbox .sport-main a {
      bottom: 80px !important;
      transform: scale(0.8);
    }

    #crisp-chatbox .sport-main>div {
      bottom: 140px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    #crisp-chatbox>.container-crisp>div {
      bottom: 0 !important;
    }

    #crisp-chatbox .container-crisp.display>div {
      bottom: 0 !important;
    }
  }
`;

export default GlobalStyle;
